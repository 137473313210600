import { Button, Card, CardContent, Grid, TextField, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import DialogMensajeDirectoTutorAlumnoForm from './DialogDifusionForm'
import moment from 'moment'
import { red } from '@material-ui/core/colors'

const AlumnoFormDatosAlumno = ( { alumno }) => {
    const [openNotificacionModal, setOpenNotificacionModal] = useState(false)
    
    const handleEnviarMensajeDirectoTutorAlumno = () => {
        setOpenNotificacionModal(true)
    }
    const handleCerrarDialorNotificacion = () => {
        setOpenNotificacionModal(false)
    }
  return (
    <>
        <Grid container xs={12} style={{margin: 0, }} spacing={2}>
            <Grid item xs={3}>
                <Card>
                    <CardContent>
                        <Typography component="h2" variant="h5" color="primary" style={{height: 70}}>
                        Tutorias Consumidas
                        </Typography>

                        <Typography align='right' component="p" variant="h4">
                            {alumno?.tutorias_individuales_consumidas}
                        </Typography>
                        
                    </CardContent>
                </Card>
            </Grid>

            <Grid item xs={3}>
                <Card>
                    <CardContent>
                        <Typography component="h2" variant="h5" color="primary" style={{height: 70}}>
                        Tutorias Disponibles
                        </Typography>

                        
                            {
                                alumno?.tutorias_individuales_disponibles === "0" 
                                ? 
                                <Typography align='right' component="p" style={{color: red[500]}} variant="h4">{alumno?.tutorias_individuales_disponibles }</Typography>
                                : 
                                <Typography align='right' component="p" variant="h4">{alumno?.tutorias_individuales_disponibles}</Typography>
                            }
                        
                        
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={3}>
                <Card>
                    <CardContent>
                        <Typography component="h2" variant="h5" color="primary" style={{height: 70}}>
                        Simulacros Corregidos
                        </Typography>

                        <Typography align='right' component="p" variant="h4">
                            {alumno?.simulacros_resueltos}
                        </Typography>
                        
                    </CardContent>
                </Card>
            </Grid>

            <Grid item xs={3}>
                <Card>
                    <CardContent>
                        <Typography component="h2" variant="h5" color="primary" style={{height: 70}}>
                        Simulacros Disponibles
                        </Typography>

                        
                            {
                                alumno?.simulacros_disponible === "0" 
                                ? 
                                <Typography align='right' component="p" style={{color: red[500]}} variant="h4">{alumno?.simulacros_disponibles }</Typography>
                                : 
                                <Typography align='right' component="p" variant="h4">{alumno?.simulacros_disponibles}</Typography>
                            }
                        
                        
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
        <Grid container  spacing={2}>

            <Grid item xs={12} md={6}>

                <TextField
                    id="alumno_nombre"
                    name="alumno_nombre"
                    label="Nombre"
                    placeholder=""
                    fullWidth
                    readonly
                    margin="normal"
                    value={alumno?.nombre}
                    variant="outlined"
                    InputLabelProps={{
                        shrink: true,
                    }}
                />

            </Grid>

            <Grid item xs={12} md={3}>

                <TextField
                    id="alumno_comunidad"
                    name="alumno_comunidad"
                    label="Comunidad autónoma"
                    placeholder=""
                    fullWidth
                    readonly
                    margin="normal"
                    value={alumno?.comunidad_autonoma_nombre}
                    variant="outlined"
                    InputLabelProps={{
                        shrink: true,
                    }}
                />

            </Grid>

            <Grid item xs={12} md={3}>

                <TextField
                    id="alumno_especialidad"
                    name="alumno_especialidad"
                    label="Especialidad"
                    placeholder=""
                    fullWidth
                    readonly
                    margin="normal"
                    value={alumno?.especialidad_nombre}
                    variant="outlined"
                    InputLabelProps={{
                        shrink: true,
                    }}
                />

            </Grid>

            <Grid item xs={12} md={6}>

                <TextField
                    id="entregas_plantilla_descripcion"
                    name="entregas_plantilla_descripcion"
                    label="Grupo"
                    placeholder=""
                    fullWidth
                    readonly
                    margin="normal"
                    value={alumno?.grupo_descripcion}
                    variant="outlined"
                    InputLabelProps={{
                        shrink: true,
                    }}
                />

            </Grid>
            
            <Grid item xs={12} md={6}>

                <TextField
                    id="entregas_plantilla_descripcion"
                    name="entregas_plantilla_descripcion"
                    label="Alumno desde"
                    placeholder=""
                    fullWidth
                    readonly
                    margin="normal"
                    value={moment(alumno?.alumno_desde).format("DD/MM/YYYY")}
                    variant="outlined"
                    InputLabelProps={{
                        shrink: true,
                    }}
                />

            </Grid>

            { alumno?.es_alumno_fast  === '0'  && <Grid item xs={12} md={12}>
                <Button color='primary' variant="contained"  onClick={() => handleEnviarMensajeDirectoTutorAlumno()}>Enviar mensaje directo al alumno</Button>
            </Grid>}
        </Grid>

        <DialogMensajeDirectoTutorAlumnoForm
            openModal={openNotificacionModal}
            handleCerrarDialorNotificacion={handleCerrarDialorNotificacion}
            alumnoId={alumno?.id}
        />
    </>
  )
}

export default AlumnoFormDatosAlumno
